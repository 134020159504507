<template>
  <section 
    class="menu"
    :class="{
      'menu--no-shadow': hide_shadow_for_sticky_filter_bar && !admin_imported,
      'menu--low-z-index': admin_imported
    }"
    ref="header"
    @mouseleave="unselect_header_item"
  >
    <div class="menu__content" :class="{ 'menu__content--admin': admin_imported && show_mobile }">
      <div class="menu__mobile" :class="{ 'menu__mobile--admin': admin_imported && show_mobile }">
        <mobile_menu_toggle
          :open_mobile_menu="open_mobile_menu"
          @toggle_mobile_menu="() => {
            open_mobile_menu = !open_mobile_menu

            if (open_mobile_menu && admin_imported) $emit('handle_click')
          }"
        />
        <language_selector :admin_imported="admin_imported"/>
        <mobile_menu
          :inside_user_guide="inside_user_guide"
          :items="show_items ? get_header_children : mobile_header_items"
          :show_back_button="show_items"
          :show="open_mobile_menu"
          :local_search="true"
          :enable_search="this.category_of_product_variety === MULTI"
          :admin_imported="admin_imported"
          @back="back_button_click"
          @clear_header_state="clear_header_state"
          @handle_click="$emit('handle_click')"
        />
      </div>
      <div
        v-if="!admin_imported || (admin_imported && !show_mobile)"
        class="menu__items"
        :style="{ textAlign: create_array_of_header_items_arrays.length > 1 ? 'center' : 'left'}"
        ref="header_items"
      >
        <span
          :class="{
            'menu__arrow menu__arrow--left': true,
            'menu__arrow--show': create_array_of_header_items_arrays.length > 1 &&
              chosen_header_items_array !== 0
          }"
          @click="chosen_header_items_array--"
        >
          <arrow/>
        </span>
        <div class="menu__items__top-wrapper">
          <div
            v-for="(header_items_array, ind) in create_array_of_header_items_arrays"
            :key="`header-array-${ind}`"
            class="menu__items__wrapper"
            :style="{
              marginLeft: ind === 0 ? ((-100 * chosen_header_items_array) + '%') : '0'
            }"
          >
            <div
              v-for="({ category, ...rest }, index) in header_items_array"
              :key="`header-${index}`"
              class="menu__item"
              @mouseover="selected_header_index = index"
            >
              <h1>
                <component
                  :is="is_product_single_or_couple_page_type(category) ? 'a' : 'router-link'"
                  class="link menu__link"
                  :to="create_link_for_menu_button(category)"
                  :href="create_link_for_menu_button(category)"
                  :event="admin_imported ? '' : 'click'"
                  itemprop="link"
                  :class="{
                    selected: selected_header_index === index
                  }"
                  :aria-label="is_product_single_or_couple_page_type(category) ? '' : `Checkout ${category} category`"
                  :target="is_product_single_or_couple_page_type(category) ? '_blank' : '_self'"
                  @click.native="$emit('handle_click')"
                >
                  <div class="menu__item__button__wrapper">
                    <div class="menu__item__button">
                      {{ get_correct_translation_value(rest, "name", [selected_language]) }}
                    </div>
                  </div>
                </component>
              </h1>
            </div>
          </div>
        </div>
        <span
          :class="{
            'menu__arrow menu__arrow--right': true,
            'menu__arrow--show': create_array_of_header_items_arrays.length > 1 &&
              chosen_header_items_array !== create_array_of_header_items_arrays.length - 1
          }"
          @click="chosen_header_items_array++"
        >
          <arrow/>
        </span>
      </div>
      <h1 class="menu__brand" :class="{ 'menu__brand--admin': admin_imported && show_mobile }">
        <img
          :src="get_store_icon"
          :alt="`${(translations || {}).website_name} logo`"
          class="menu__logo"
          itemprop="logo"
          ref="logo"
          aria-label="Go to homepage"
          @click="admin_imported ? $emit('handle_click') : $router.push(create_home_url)"
        />
      </h1>
      <div class="menu__other-buttons" :class="{ 'menu__other-buttons--admin': admin_imported && show_mobile }">
        <language_selector v-if="!is_mobile_device" :admin_imported="admin_imported"/>

        <router-link
          v-if="has_about_page"
          class="link"
          to="/about"
          :event="admin_imported ? '' : 'click'"
          itemprop="category"
          aria-label="About us page"
          @click.native="$emit('handle_click')"
        >
          <div
            class="menu__about other-buttons other-buttons__secondary"
            :class="{ 'other-buttons__secondary--admin': admin_imported && show_mobile }"
          >
            <about_icon/>
          </div>
        </router-link>

        <router-link
          v-if="!is_mobile_device"
          class="link"
          to="/"
          :event="admin_imported ? '' : 'click'"
          itemprop="home"
          aria-label="Go to homepage"
          @click.native="$emit('handle_click')"
        >
          <div class="other-buttons other-buttons__secondary menu__home" :class="{ 'other-buttons__secondary--admin': admin_imported && show_mobile }">
            <home_icon/>
          </div>
        </router-link>

        <router-link
          class="link"
          to="/Products/liked/"
          :event="admin_imported ? '' : 'click'"
          itemprop="category"
          aria-label="Checkout your liked items"
          @click.native="$emit('handle_click')"
        >
          <div class="other-buttons other-buttons__secondary" :class="{ 'other-buttons__secondary--admin': admin_imported && show_mobile }">
            <span v-if="number_of_liked_products !== 0" class="other-buttons__number">{{ number_of_liked_products }}</span>
            <heart_icon/>
          </div>
        </router-link>

        <router-link
          class="link"
          to="/basket"
          :event="admin_imported ? '' : 'click'"
          itemprop="category"
          aria-label="Go to your basket"
          @click.native="$emit('handle_click')"
        >
          <div
            data-cy="cart"
            class="other-buttons other-buttons__basket"
            :class="{
              'other-buttons__basket--ading-to-basket': adding_to_basket
            }"
            @mouseover="!admin_imported && $route.params && $route.params.type !== 'basket' && toggle_preview_cart([true])"
            @mouseleave="!admin_imported && $route.params && $route.params.type !== 'basket' && close_preview_basket()"
          >
            <span v-if="number_of_items_in_cart > 0" class="other-buttons__number">{{ number_of_items_in_cart }}</span>
            <basket_icon :fill="project_styles.colors[adding_to_basket ? 'green_color' : 'item_name_color']" />
          </div>
        </router-link>
      </div>
      <preview_basket v-if="$route.params && $route.params.type !== 'basket' && !admin_imported"/>
    </div>
    <menu_contents
      :data="header_content_data"
      :admin_imported="admin_imported"
      @mouseover="mouse_if_over_header_content = true"
      @mouseout="mouse_if_over_header_content = false"
      @handle_click="$emit('handle_click')"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { MULTI, COUPLE } from "../../../../data/product_variety_types"
import { home, image_dimension_names } from "../../../../data/other_constants"
import { UPDATE_HEADER_HEIGHT } from "../../stores/Styles/constants"
import { CLIENT_STORE, STYLES_STORE } from "../../constants/other"
import { deleted, CONTENT_STORE, CONFIGURATION_STORE } from "../../../Admin/constants/others_constants"
import heart_icon from "../../../Shared/components/icon_components/heart-icon"
import basket_icon from "../../../Shared/components/icon_components/basket-icon"
import home_icon from "../../../Shared/components/icon_components/home-icon"
import about_icon from "../../../Shared/components/icon_components/about-icon-client"
import arrow from "../../../Shared/components/icon_components/arrow.vue"
import { collection, default_logo_src, product, SHARED_STORE } from "../../../Shared/constants/other"
import mobile_menu_toggle from "../../../Shared/components/utils/mobile_menu_toggle"
import mobile_menu from "../../../Shared/components/utils/mobile_menu"
import { TOGGLE_PREVIEW_BASKET } from "../../../Shared/stores/Shared/constants"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"
import { event_hub } from "../../../../main"
import get_image_src from "../../../Shared/methods/get_image_src"

const language_selector = () => import("../utils/language_selector")
const preview_basket = () => import("../../../Shared/components/utils/basket/preview_basket")
const menu_contents = () => import("../utils/menu_contents")

export default {
  components: {
    menu_contents,
    preview_basket,
    mobile_menu,
    language_selector,
    heart_icon,
    basket_icon,
    about_icon,
    mobile_menu_toggle,
    arrow,
    home_icon
  },
  props: {
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    },
    show_mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    guide_data: {
      type: Object | null,
      required: false,
      default: null
    },
    inside_user_guide: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selected_header_index: "",
      hide_shadow_for_sticky_filter_bar: false,
      adding_to_basket: false,
      mouse_if_over_header_content: false,
      component_mounted: false,
      open_mobile_menu: false,
      show_items: false,
      average_width_of_header_item_per_letter: 9,
      chosen_header_items_array: 0,
      width_of_header_items: 0,
      selected_header_id: null,
      home,
      MULTI
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device",
      "basket_items",
      "store_id"
    ]),
    ...mapState(CLIENT_STORE, [
      "header_items",
      "translations",
      "products",
      "category_of_product_variety",
      "selected_entity",
      "collections",
      "project_styles",
      "rest_config",
      "liked_products",
      "about_page_content",
      "selected_language",
      "has_about_page"
    ]),
    ...mapState(CONFIGURATION_STORE, { admin_rest_config: "rest_config" }),
    ...mapState(CONTENT_STORE, { admin_header_items: "header_items" }),
    get_store_icon() {
      const icon = (this.rest_config || this.admin_rest_config || {}).icon

      return (icon && typeof icon === "string") ?
        icon :
        this.guide_data?.uploaded_logo || get_image_src(icon, image_dimension_names.mobile, true)
    },
    number_of_items_in_cart() {
      return this.basket_items.reduce((total, { quantity }) => total + Number(quantity), 0)
    },
    number_of_liked_products() {
      return this.liked_products.length
    },
    correct_header_items() {
      if (this.guide_data?.menu_items) return this.guide_data.menu_items

      return this.admin_imported ?
        this.admin_header_items.filter(({ change_type }) => change_type !== deleted) :
        this.header_items || []
    },
    mobile_header_items() {
      return [
        ...(this.correct_header_items.length ? this.correct_header_items
          .map(({ category, sub_items, translations, ...rest }) => (
            {
              title: get_correct_translation_value({ translations }, "name", [this.selected_language]),
              click: () => { this.show_items = true; this.selected_header_id = category },
              link: this.determine_mobile_link(sub_items, category),
              target: this.is_product_single_or_couple_page_type(category) ? "_blank" : "_self",
              id: rest.id,
              category,
              ...(sub_items && {
                children: sub_items
                  .filter(({ change_type }) => change_type !== deleted)
                  .map(({ sub_category, id, ...rest }) => ({
                    id,
                    name: get_correct_translation_value(
                      rest,
                      "name",
                      [this.selected_language]
                    ),
                    link: `/Products/${category}/${sub_category}`
                  }))
              })
            })
          )
          .sort(({ children }, { children: second_children }) => {
            if (!children && second_children) return 1
            if (!children && !second_children) return 0
            if (children  && !second_children) return -1
            return 0
          }) : []
        )
      ]
    },
    get_header_children() {
      return this.selected_header_id ?
        this.mobile_header_items.find(
          ({ category }) => category === this.selected_header_id
        ).children :
        []
    },
    create_array_of_header_items_arrays() {
      if (
        this.component_mounted && this.$refs.header_items &&
        ((this.admin_imported && !this.is_mobile_device) || !this.admin_imported)
      ) {
        const header_items_widths_array = this.correct_header_items.map((item) => 
          get_correct_translation_value(item, "name", [this.selected_language]).length *
          this.average_width_of_header_item_per_letter + 60 // padding
        )
        const max_width_of_header_items = Number(
          window.getComputedStyle(this.$refs.header_items, null)
            .getPropertyValue("width")
            .replace("px", "")
        )

        let temp_remaining_width = max_width_of_header_items
        let temp_last_array_index = 0

        return header_items_widths_array.reduce((total, header_item_width, index) => {
          if(temp_remaining_width - header_item_width > 0) {
            total[temp_last_array_index].push(this.correct_header_items[index])
            temp_remaining_width -= header_item_width
          }
          else {
            total = [
              ...total, 
              [
                this.correct_header_items[index]
              ]
            ]
            temp_last_array_index = total.length - 1
            temp_remaining_width = max_width_of_header_items - header_item_width
          }

          return total
        }, [[]]).filter(([el]) => el)
      }

      return [[]]
    },
    header_content_data() {
      return (
        this.selected_header_index !== "" &&
        this.create_array_of_header_items_arrays[this.chosen_header_items_array][this.selected_header_index]
      ) ? this.create_array_of_header_items_arrays[this.chosen_header_items_array][this.selected_header_index] : {}
    },
    create_home_url() {
      return this.category_of_product_variety === MULTI ?
        "/" :
        `${window.location.origin}/?${
          this.category_of_product_variety === COUPLE ? collection : product
        }=${this.selected_entity}`
    }
  },
  watch: {
    correct_header_items() {
      // Reset on update in Content admin
      this.component_mounted = false
      this.$nextTick(() => this.component_mounted = true)
      this.chosen_header_items_array = 0
    },
    $route() {
      this.clear_header_state()
    },
    is_mobile_device() {
      this.component_mounted = false
      this.$nextTick(() => this.component_mounted = true)
    }
  },
  mounted() {
    this.component_mounted = true
    event_hub.$on("hide_shadow_for_sticky_filter_bar", (decision) =>  this.hide_shadow_for_sticky_filter_bar = decision)
    event_hub.$on("blink_basket_green", () => {
      this.adding_to_basket = true

      setTimeout(() => {
        this.adding_to_basket = false
      }, 1000)
    })

    if (!this.is_mobile_device && this.$refs.header_items)
      this.width_of_header_items = Number(window
        .getComputedStyle(this.$refs.header_items)
        .width
        .replace("px", "")
      )

    this.$nextTick(() => this.update_height(this.$refs.header.clientHeight))
  },
  methods: {
    ...mapMutations(STYLES_STORE, {
      update_height: UPDATE_HEADER_HEIGHT
    }),
    ...mapMutations(SHARED_STORE, {
      toggle_preview_cart: TOGGLE_PREVIEW_BASKET
    }),
    get_correct_translation_value,
    close_preview_basket() {
      // Wait for one second if user moves mouse over preview basket
      setTimeout(() => this.toggle_preview_cart([false]), 300)
    },
    back_button_click() {
      this.selected_header_id = null
      this.show_items = false
    },
    clear_header_state() {
      this.open_mobile_menu = false
      this.selected_header_id = null
      this.show_items = false
    },
    determine_mobile_link(sub_items, category) {
      if (sub_items) return ""
      if (home === category) return this.create_home_url
      return this.create_link_for_menu_button(category)
    },
    /**
     * 
     */
    unselect_header_item() {
      setTimeout(() => {
        if (!this.mouse_if_over_header_content) this.selected_header_index = ""
      }, 100);
    },
    /**
     * 
     */
    is_product_single_or_couple_page_type(category_of_item) {
      return [`?${collection}=`, `?${product}=`].some(item => category_of_item.includes(item))
    },
    /**
     * 
     */
    get_subcategories(arr) {
      return arr.reduce((total, current) => total = [...total, ...current.map(obj => obj.value)], [])
    },
    /**
     * 
     */
    create_link_for_menu_button(category) {
      if (category === home) return this.create_home_url

      return this.is_product_single_or_couple_page_type(category) ?
        `${window.location.origin}/${this.store_id ? this.store_id + "/" : ""}${category}` :
        `/Products/${category}`
    }
  }
} 
</script>

<style lang="scss" scoped>
  @use "sass:math";
  @use "../../../../styles/_global-constants" as *;

  $icon-height: 15px;
  $other-buttons-mobile-width: 105px;

  .menu {
    background-color: var(--background_heavy);
    padding: 0 5px;
    margin-bottom: 1px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 101; // Because of paypal buttons
    box-shadow: 0 2px 6px 0 rgba($pure-black, 0.2);
    height: $menu-height;

    @media (max-width: $mobile) {
      padding: 0;
    }

    &__about {
      width: unset;

      @media (max-width: $mobile--small) {
        width: 30px !important;
      }
    }

    &__home {
      @media (max-width: $tablet) {
        display: none !important;
      }
    }

    &--no-shadow {
      box-shadow: none;
    }

    &--low-z-index {
      z-index: 2;
    }

    &__mobile {
      display: none;
      grid-area: header-items;

      &--admin {
        display: block;
      }

      @media (max-width: $tablet) {
        display: block;
      }
    }

    &__arrow {
      position: absolute;
      top:  calc((#{$menu-height - $icon-height}) / 2 + -1px);
      width: $icon-height;
      height: $icon-height;
      cursor: pointer;
      opacity: 0;
      z-index: -1;
      transition: .4s ease-in-out;
      fill: var(--item_name_color);

      svg {
        width: 100%;
        height: 100%;
      }

      &--right {
        right: 4px;
        animation: pulse-forward-right 2.5s linear infinite;
      }

      &--left {
        left: 4px;
        transform: rotate(180deg);
        animation: pulse-forward-left 2.5s linear infinite;
      }

      &--show {
        opacity: 1;
        z-index: 2;
      }
    }

    &__brand {
      display: inline-flex;
      width: $max-logo-width;
      margin: 0;
      height: $menu-height;
      text-align: center;
      vertical-align: top;
      grid-area: header-logo;
      align-items: center;
      justify-content: center;
      padding: 0;
      cursor: pointer;

      &--admin {
        height: $menu-height;
        width: 100%;
      }

      @media (max-width: $tablet) {
        height: $menu-height;
        width: 100%;
      }
    }

    &__logo {
      width: calc(#{$max-logo-width} - 10px);
      height: calc(#{$menu-height} - 15px);
      object-fit: contain;

      @media (max-width: $tablet) {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
      }
    }

    &__content {
      position: relative;
      max-width: $max-menu-width;
      margin: auto;
      text-align: left;
      color: $pure-white;
      height: $menu-height;

      &--admin {
        display: grid;
        padding: 0;
        grid-template-columns: calc(2 * 50px + 5px) calc(100% - 50px * 4 - 10px) calc(2 * 50px + 5px) !important;
        grid-template-areas: "header-items header-logo header-buttons";
        line-height: $menu-height;
      }

      @media (max-width: $tablet) {
        display: grid;
        padding: 0;
        grid-template-columns: calc(2 * #{$menu-height} + 5px) calc(100% - #{$menu-height} * 4 - 10px) calc(2 * #{$menu-height} + 5px);
        grid-template-areas: "header-items header-logo header-buttons";
        line-height: $menu-height;
      }
    }

    &__title {
      color: $pure-white;
      font-size: 22px;
      display: inline-block;
      margin: 0;
      vertical-align: top;
    }

    &__items {
      position: relative;
      display: inline-block;
      width: calc(50% - #{math.div($max-logo-width, 2)});
      padding: 0 20px;
      box-sizing: border-box;

      @media (max-width: $tablet) {
        display: none;
      }

      &__top-wrapper {
        white-space: nowrap;
        overflow: hidden;
      }

      &__wrapper {
        display: inline-block;
        width: 100%;
        margin-left: 0px;
        transition: margin .3s ease-in-out; 
        text-align: left;
      }
    }

    &__item {
      display: inline-block;
      width: auto;
      height: $menu-height;
      padding: 0;
      margin: 0;
      line-height: $menu-height;
      font-size: 16px;
      transition: 250ms ease-in-out;
      vertical-align: top;
      cursor: pointer;

      h1 {
        margin: 0;
        padding: 0;
        width: 100%;
        height: $menu-height;
        font-size: 16.5px;
      }

      &__button {
        position: relative;
        padding: 0 10px;
        color: var(--item_name_color);
        line-height: normal;
        font-weight: 700;
        letter-spacing: 1.3px;
      }
    }

    &__other-buttons {
      position: relative;
      display: inline-flex;
      width: calc(50% - #{math.div($max-logo-width, 2)} - 10px); // 10px offset
      box-sizing: border-box;
      text-align: right;
      vertical-align: top;
      height: $menu-height;
      grid-area: header-buttons;
      justify-content: flex-end;
      align-items: flex-start;

      &--admin {
        float: right;
        width: $other-buttons-mobile-width;
        text-align: center;
      }

      @media (max-width: $tablet) {
        float: right;
        width: 100%;
        text-align: center;
      }

      svg {
        height: 25px;
        vertical-align: middle;
        fill: var(--item_name_color);
        stroke: var(--item_name_color);
      }

      &__basket {
        grid-area: header-basket;
        margin-right: 5px;
      }

      div {
        display: inline-flex;
        padding: 0 10px;
        height: $menu-height;
        position: relative;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        &:last-child {
          padding: 0;
        }
      }

      span {
        position: absolute;
        display: block;
        top: 28px;
        width: 15px;
        height: 15px;
        font-size: 10px;
        left: 57%;
        color: var(--font_heavy);
        border-radius: 100%;
        background-color: var(--background_light);
        text-align: center;
        line-height: 15px;
        box-sizing: border-box;
        z-index: 1;
      }
    }

    &__link {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &:before {
        position: absolute;
        display: block;
        left: 45%;
        right: 0;
        opacity: 0;
        bottom: -30px;
        max-height: 12px;
        background-color: $pure-white;
        transition: $default-transition;
        content: "";
        transform: rotate(-45deg);
      }

      &:hover {
        &:before {
          height: 12px;
          width:  12px;
          bottom: -22px;
        }
      }

      &.selected {
        &:before {
          position: absolute;
          display: block;
          bottom: -7px;
          left: 45%;
          right: 0;
          height: 12px;
          width:  12px;
          background: var(--body_color);
          content: "";
          z-index: 1;
          opacity: 1;
        }
      }
    }
  }

  .other-buttons {
    display: inline-block;
    width: $menu-height;
    text-align: center;

    &__secondary {
      &--admin {
        width: 50px;
      }

      @media (max-width: $tablet) {
        width: 50px;
      }
    }

    &__basket {
      svg {
        transition: 250ms ease-out;
        fill: var(--item_name_color);
      }

      &--ading-to-basket {
        svg {
          transform: scale(1.2) !important;
        }
      }
      
      &--admin {
        width: 50px;
      }

      @media (max-width: $tablet) {
        width: 50px;

        svg {
          transform: scale(1.2) !important;
        }
      }
    }
  }

  .cart {
    height: 28px !important;
    fill: $pure-black !important;
  }

  @keyframes pulse-forward-left {
    72% {
      transform: translateX(0) rotate(180deg);
    }
    78% {
      transform: translateX(-3px) rotate(180deg);
    }
    84% {
      transform: translateX(0) rotate(180deg);
    }
    90% {
      transform: translateX(-3px) rotate(180deg);
    }
    96% {
      transform: translateX(0) rotate(180deg);
    }
    100% {
      transform: translateX(0) rotate(180deg);
    }
  }

  @keyframes pulse-forward-right {
    72% {
      transform: translateX(0);
    }
    78% {
      transform: translateX(3px);
    }
    84% {
      transform: translateX(0);
    }
    90% {
      transform: translateX(3px);
    }
    96% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
</style>
