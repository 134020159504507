<template functional>
<svg id="Layer_1" :fill="props.fill" height="24" width="24" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 871 977.05"><path d="M1440,1028.1H1078v-369H943.7v369H569V486.55l435.51-435.5L1440,486.55Zm-280-82h198V520.51L1004.51,167,651,520.51V946.09H861.69v-369H1160Z" transform="translate(-569 -51.05)"/></svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      required: false
    }
  }
}
</script>

