<template functional>
 <svg id="Layer_1" height="30" width="30" class="shopping-cart-icon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 856.97 666.9"><polyline class="cls-1" points="25 25 134.07 72.72 219.27 457.85"/><polygon class="cls-1" points="151.11 144.29 825.95 144.29 757.78 457.85 219.27 457.85 151.11 144.29"/><line class="cls-1" x1="338.56" y1="249.95" x2="645.31" y2="249.95"/><line class="cls-1" x1="372.65" y1="359.01" x2="611.23" y2="359.01"/><circle class="cls-1" cx="655.54" cy="590.78" r="51.12"/><circle class="cls-1" cx="321.52" cy="590.78" r="51.12"/></svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .shopping-cart-icon {
    fill:none;
    stroke: var(--item_name_color);
    stroke-linecap:round;
    stroke-miterlimit:10;
    stroke-width:60px;
  }
</style>
