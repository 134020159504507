<template functional>
<svg id="Layer_1" :fill="props.fill" height="24" width="24" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1044.8 919.14"><path d="M1541.69,303l0,.17h0A1.54,1.54,0,0,1,1541.69,303Z" transform="translate(-507.87 -106.86)"/><path d="M1542.69,306.32c-.1-.32-.19-.64-.29-1l-.67-2.17,0-.17,0,0c-33-102.52-122.93-178.39-229.16-193.28-82.22-11.57-165.1,12.77-227.15,66.72a283.52,283.52,0,0,0-55,64.58A284,284,0,0,0,748.21,109.7c-106.33,14.91-196.28,90.86-229,192.85l-1.22,3.8c-25.12,81.38-2.75,172.25,58.32,237.18L1030.38,1026l454-482.47C1545.12,479,1567.58,388.74,1542.69,306.32ZM1422.21,485l-391.83,416.4L638.51,485c-40.09-42.61-55-101.43-38.2-155.78,23-71.64,85.66-124.65,159.74-135a213,213,0,0,1,29.34-2.05,196.14,196.14,0,0,1,130,48.66,198.59,198.59,0,0,1,68.32,149.92h85.36a198.59,198.59,0,0,1,68.33-149.93c44-38.29,100.55-54.8,159.29-46.6,74,10.38,136.68,63.31,159.71,134.87l.75,2.42C1477.22,383.61,1462.3,442.43,1422.21,485Z" transform="translate(-507.87 -106.86)"/></svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      required: false
    }
  }
}
</script>

